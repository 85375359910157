import React from 'react'
import '../Styles/Footer1.css'

const Footer1 = () => {
  return (
    <>
    <div className='foot'>
    <div className='foot1'>
       
       <div className='foot2'>
          
       <a href='/privacyPolicy'><div className='foot2a'>Privacy Policy</div></a>
       <a href='/termsAndConditions'><div className='foot2a'>Terms and Conditions</div></a>
       <a href='/refundPolicy'><div className='foot2a'>Refund Policy</div></a>
       <a href=''><div className='foot2a'>Sitemap</div></a>

       </div>
       

       <div className='foot3'>
          <div className='foot13ab'>©2025 Hidden valley Estates</div>
          <div className='foot13ab'><a href='https://newtabtechlab.in/'>Designed & Developed by New Tab Tech Lab</a></div>
       </div>

    </div>
    </div>
      
    </>
  )
}

export default Footer1

import React from 'react'
// import Navbar1 from './Components/Navbar1'
import Pages from './Pages'
import { ToastContainer } from 'react-toastify';


const App = () => {
  return (
    <>
    

            <ToastContainer />

      <Pages/>
    
    </>
  )
}

export default App
